import React, { memo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './TitleCTA.scss';

import GatsbyImage from 'gatsby-image';
import { graphql } from 'gatsby';
import { CTALink } from '../CTA';
import { customPropTypes, useCSSMotion, useCTAClickTracking, useSectionTimeSpent } from '../../util';

function TitleCTA({ data: { title, description, cta, icon, name, id } }) {
  const containerRef = useRef(null);
  const motionState = useCSSMotion(containerRef);

  const handleCtaClick = useCTAClickTracking(name);

  useSectionTimeSpent(name, containerRef);

  return (
    <div id={id} className={classnames('TitleCTA', motionState)} ref={containerRef}>
      {icon?.localFile && (
        <GatsbyImage className="logo" fluid={icon?.localFile.childImageSharp.fluid} alt={icon?.altText} />
      )}
      <div className="flex-container">
        <div className="left-container">
          <h3 className="title">{title}</h3>
        </div>
        <div className="right-container">
          <p className="description">{description}</p>
          {cta?.link && (
            <CTALink
              onClick={handleCtaClick}
              link={cta.link}
              text={cta.text}
              ariatext={cta.text}
              motionState={motionState}
            />
          )}
        </div>
      </div>
    </div>
  );
}

TitleCTA.propTypes = checkExtraProps({
  data: PropTypes.shape({
    className: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    }),
    icon: PropTypes.oneOfType([customPropTypes.image, PropTypes.oneOf([false])]),
  }).isRequired,
});

TitleCTA.defaultProps = {};

export default memo(TitleCTA);

export const fragments = graphql`
  fragment TitleCta on WpPage_Flexiblelayout_FlexibleChildren_TitleCta {
    className
    cta {
      text
      link
    }
    description
    icon {
      ...Icon
    }
    title
    id
    jumpToLinkTitle
    name
  }

  fragment TitleCta_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_TitleCta {
    className
    cta {
      text
      link
    }
    description
    icon {
      ...Icon
    }
    title
    id
    jumpToLinkTitle
    name
  }
`;
